import { useState, type FormEvent, type FormEventHandler } from 'react';
import { DialogTrigger, Form } from 'react-aria-components';
import { Button } from 'components/ui/button';
import { Drawer, DrawerHeader, DrawerRoot, DrawerX } from 'components/ui/drawer';
import { useI18n } from 'context/i18n';

import { getOrderInfo } from 'actions/products';
import { checkProfile } from 'actions/general';
import { COOKIES, eraseCookie, setCookie, getMarket } from 'utils/helpers';
import { getCountryFromMarket } from 'utils/translations';
import { useLocale } from 'hooks';
import { TextField } from 'components/ui/text-field';
import { getServerError } from 'utils/request';

type CamperlabMyOrdersDrawer = {
  visible: boolean;
  onClose: (isOpen: boolean) => void;
};

export function CamperlabMyOrders({ visible = false, onClose }: CamperlabMyOrdersDrawer) {
  const [status, setStatus] = useState<'idle' | 'pending'>('idle');
  const [error, setError] = useState<string | null>(null);
  const { t, locale } = useI18n();
  const { country: market } = useLocale();
  const country = getCountryFromMarket(market);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus('pending');
    if (error) setError(null);
    const formData = Object.fromEntries(new FormData(e.currentTarget).entries()) as Record<string, string>;
    const email = formData.email.trim();
    const locator = formData.locator.trim();
    try {
      const emailValue = email;
      const locatorValue = locator;
      // @ts-ignore
      const orderInfoRs = await getOrderInfo({
        lang: locale,
        email: emailValue,
        locator: locatorValue,
      });

      const { sapActive, message } = orderInfoRs;

      if (sapActive === false) {
        setStatus('idle');
        setError(message);
        return false;
      }

      const order = { email: emailValue, locator: locatorValue };
      const expirationDays = 1;
      eraseCookie(COOKIES.ORDER_DETAILS);
      setCookie(COOKIES.ORDER_DETAILS, encodeURI(JSON.stringify(order)), expirationDays);
      let localeOrder = locale;
      if (orderInfoRs.countryId && orderInfoRs.languageId) {
        localeOrder = `${orderInfoRs.languageId}_${orderInfoRs.countryId}`;
      } else {
        const auxProfile = await checkProfile(localeOrder);
        localeOrder = auxProfile.defaultProfile;
      }
      window.location.assign(`${window.location.origin}/${localeOrder}/myaccount/myorders/orderdetails`);
    } catch (responseError) {
      const error = getServerError(responseError);
      console.error(error);
      setError(error.message);
      setStatus('idle');
    }
  };

  return (
    <DialogTrigger isOpen={visible} onOpenChange={(isOpen) => onClose(isOpen)}>
      <DrawerRoot className="w-full">
        <Drawer>
          <div className="relative flex h-full max-h-[100dvh] flex-grow flex-col overflow-x-hidden">
            <DrawerHeader label={t('menu.superior', 'mis.pedidos', 'Orders')} className="px-2.5" />
            <Form onSubmit={onSubmit} className="flex w-full flex-grow flex-col">
              <div className="flex-grow px-2.5">
                <div className="flex flex-shrink-0 flex-col">
                  <p className={`mb-2 text-sm font-bold uppercase lg:text-xs`}>{t('menu.superior', 'mis.pedidos.title')}</p>
                  <p className="mb-5 text-sm lg:text-xs">{t('menu.superior', 'mis.pedidos.description')}</p>
                  <p className="mb-5 text-sm lg:text-xs">{t('menu.superior', 'mis.pedidos.info')}</p>

                  <div className="flex flex-col gap-2">
                    <TextField label={t('ficha.producto', 'notify.email', 'Email')} name="email" type="email" isRequired />
                    <TextField label={t('menu.superior', 'n.de.pedido', 'Order Number')} name="locator" type="text" isRequired />
                    <p className="mt-2 text-sm text-error empty:hidden lg:text-xs">{error}</p>
                  </div>
                </div>
              </div>
              <div className="sticky bottom-0 flex flex-shrink-0 flex-col gap-2.5 bg-clear p-2.5">
                <Button type="submit" isPending={status === 'pending'}>
                  <span className="text-sm md:text-xs">{t('ficha.producto', 'notify.confirm', 'Confirm')}</span>
                </Button>
              </div>
            </Form>
          </div>
        </Drawer>
      </DrawerRoot>
    </DialogTrigger>
  );
}
